import "core-js/stable";
import "regenerator-runtime/runtime";

import {
  AppBar,
  IconMenu,
  Layout,
  List,
  ListItem,
  MenuItem,
  NavDrawer,
  Panel,
  ProgressBar,
  Button,
} from "react-toolbox";
import Auth from "@aws-amplify/auth";
import { Col, Row } from "react-flexbox-grid";
import { Link, Route, Switch } from "react-router-dom";
import { graphql } from "react-apollo";
import React, { useState } from "react";
import { Button as MaterialButton } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IfFeatureEnabled } from "@growthbook/growthbook-react";

import PropTypes from "prop-types";

import CSVtoAccount from "../../containers/csv-to-account";
import CreateAdhocRequest from "../../containers/create-adhoc-request";
import CreateOrganisation from "../../containers/create-organisation";
import CreateOrganisationAdmin from "../../containers/create-organisation-admin";
import CreatePlaylist from "../../containers/create-playlist";
import CreateRequest from "../../containers/create-request";
import CreateResource from "../../containers/create-resource";
import CreateAmazonResource from "../../containers/create-amazon-resource";
import CreateUser from "../../containers/create-user";
import FeatureToggles from "../../containers/feature-toggles";
import Home from "../../containers/home";
import Login from "../login";
import Organisation from "../../containers/organisation";
import Organisations from "../../containers/organisations";
import OutdatedEvents from "../../containers/outdated-events";
import Playlists from "../../containers/playlists";
import Request from "../../containers/request";
import Requests from "../../containers/requests";
import Resources from "../../containers/resources";
import Subjects from "../../containers/subjects";
import Suppliers from "../../containers/suppliers";
import UpdatePlaylist from "../../containers/update-playlist";
import UpdateResource from "../../containers/update-resource";
import UpdateUser from "../../containers/update-user";
import Users from "../../containers/users";

import theme from "./theme.css";
import whoami from "./whoami.graphql";

const logout = () => {
  Auth.signOut().then(() => {
    location.reload();
  });
};

const UserMenu = () => (
  <IconMenu icon="more_vert" position="topRight" theme={theme} menuRipple>
    <MenuItem icon="exit_to_app" caption="Logout" onClick={logout} />
  </IconMenu>
);

const UrlAlert = ({ url }) => {
  const [urlDialog, setUrlDialog] = useState(true);

  return (
    <Dialog open={urlDialog}>
      <DialogTitle id="alert-dialog-title">
        Achtung! You are using the old admin URL
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please use{" "}
          <a target="blank" href={url}>
            {url}
          </a>{" "}
          from now on ❤️. We will deprecate this URL soon.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MaterialButton
          onClick={() => setUrlDialog(false)}
          color="primary"
          autoFocus
        >
          Got it
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

UrlAlert.propTypes = {
  url: PropTypes.string,
};

const NonAdmin = () => (
  <Row center="xs">
    <Col sm={8}>
      <div>
        <h1>🙅</h1>
        <h3>Sry, only for admin users</h3>
        <Button icon="exit_to_app" onClick={logout}>
          Logout
        </Button>
      </div>
    </Col>
  </Row>
);

UserMenu.propTypes = {
  client: PropTypes.func,
};

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerActive: false,
    };

    this.toggleDrawerActive = this.toggleDrawerActive.bind(this);
  }

  toggleDrawerActive() {
    this.setState({ drawerActive: !this.state.drawerActive });
  }

  render() {
    const { loading, user } = this.props.data;

    if (loading) {
      return (
        <Layout className={theme.container}>
          <ProgressBar mode="indeterminate" />
        </Layout>
      );
    }

    if (!user) {
      return <Login />;
    }

    if (!user.admin) {
      return (
        <Layout>
          <NonAdmin />
        </Layout>
      );
    }

    return (
      <Layout>
        <NavDrawer
          active={this.state.drawerActive}
          permanentAt="xxxl"
          onOverlayClick={this.toggleDrawerActive}
        >
          <div style={{ paddingTop: "60px" }}>
            <List selectable ripple>
              <ListItem
                leftIcon="home"
                itemContent={
                  <Link className={theme.link} to="/">
                    Home
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="library_books"
                itemContent={
                  <Link className={theme.link} to="/resources/">
                    Resources
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="grid_on"
                itemContent={
                  <Link className={theme.link} to="/playlists/">
                    Playlists
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="face"
                itemContent={
                  <Link className={theme.link} to="/users/">
                    Users
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="account_balance"
                itemContent={
                  <Link className={theme.link} to="/organisations/">
                    Organisations
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="business"
                itemContent={
                  <Link className={theme.link} to="/suppliers/">
                    Suppliers
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="subject"
                itemContent={
                  <Link className={theme.link} to="/subjects/">
                    Subjects
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <ListItem
                leftIcon="attach_money"
                itemContent={
                  <Link className={theme.link} to="/requests/">
                    Requests
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
              <IfFeatureEnabled feature="promotional_banner_mvp">
                <ListItem
                  leftIcon="ad_units"
                  itemContent={
                    <Link className={theme.link} to="/banners">
                      Banners
                    </Link>
                  }
                  onClick={this.toggleDrawerActive}
                />
              </IfFeatureEnabled>
              <ListItem
                leftIcon="settings_input_component"
                itemContent={
                  <Link className={theme.link} to="/toggles/">
                    Feature Toggles
                  </Link>
                }
                onClick={this.toggleDrawerActive}
              />
            </List>
          </div>
        </NavDrawer>
        <Panel>
          <AppBar
            leftIcon="menu"
            title="Learnerbly Back Office"
            onLeftIconClick={this.toggleDrawerActive}
            fixed
          >
            <UserMenu />
          </AppBar>
          <div className={theme.container} style={{ paddingTop: "90px" }}>
            {window.location.hostname === "admin.app.learnerbly.com" && (
              <UrlAlert url="https://backoffice.app.learner.be" />
            )}
            {window.location.hostname ===
              "admin.development.learnerbly.com" && (
              <UrlAlert url="https://backoffice.app.learner.dev" />
            )}
            <Switch>
              <Route path="/resources/create" component={CreateResource} />
              <Route
                path="/resources/create-amazon"
                component={CreateAmazonResource}
              />
              <Route
                path="/resources/outdated-events"
                component={OutdatedEvents}
              />
              <Route
                path="/resources/:resourceId/"
                component={UpdateResource}
              />
              <Route path="/resources/" component={Resources} />
              <Route path="/playlists/create" component={CreatePlaylist} />
              <Route path="/playlists/:playlistId" component={UpdatePlaylist} />
              <Route path="/playlists/" component={Playlists} />
              <Route path="/requests/request/:requestId" component={Request} />
              <Route
                path="/requests/create-adhoc"
                component={CreateAdhocRequest}
              />
              <Route path="/requests/create" component={CreateRequest} />
              <Route path="/requests/" component={Requests} />
              <Route
                path="/users/create/:organisationId"
                render={(routeProps) => (
                  <CreateUser currentUser={user} {...routeProps} />
                )}
              />
              <Route
                path="/users/csv-to-account/"
                render={(routeProps) => (
                  <CSVtoAccount currentUser={user} {...routeProps} />
                )}
              />
              <Route
                path="/users/:userId/"
                render={(routeProps) => (
                  <UpdateUser currentUser={user} {...routeProps} />
                )}
              />
              <Route path="/users/" component={Users} />
              <Route
                path="/organisations/create"
                component={CreateOrganisation}
              />
              <Route
                path="/organisations/:organisationId/create-admin/"
                render={(routeProps) => (
                  <CreateOrganisationAdmin currentUser={user} {...routeProps} />
                )}
              />
              <Route
                path="/organisations/:organisationId/:tab"
                render={(routeProps) => (
                  <Organisation currentUser={user} {...routeProps} />
                )}
              />
              <Route
                path="/organisations/:organisationId/"
                render={(routeProps) => (
                  <Organisation currentUser={user} {...routeProps} />
                )}
              />
              <Route path="/organisations/" component={Organisations} />
              <Route path="/subjects/" component={Subjects} />
              <Route path="/toggles/" component={FeatureToggles} />
              <Route path="/suppliers/" component={Suppliers} />
              <Route path="/" component={() => <Home {...{ user }} />} exact />
              <IfFeatureEnabled feature="promotional_banner_mvp">
                <Route path="/banners" component={() => <p>Banners List</p>} />
                <Route
                  path="/banners/create"
                  component={() => <p>Banners Create</p>}
                />
                <Route
                  path="/banners/:bannerId"
                  component={() => <p>Banners Edit</p>}
                />
              </IfFeatureEnabled>
            </Switch>
          </div>
        </Panel>
      </Layout>
    );
  }
}

Main.propTypes = {
  data: PropTypes.object,
};

export default graphql(whoami)(Main);
